import React from 'react'
import VideoModal from '../ModalVideo'
import SectionTitle from '../../components/SectionTitle'
import story from "../../images/Home/story1.jpg";


const VideoSection = (props) => {
    return(
        <section className="wpo-video-section section-padding">
            <div className="container">
                <SectionTitle topTitle={'Wedding Ceremony'} MainTitle={'Celebrating Your Love'}/>
                <div className="wpo-video-item">
                    <div className="wpo-video-img">
                        <img style={{width:"100%"}} src={story} alt=""/>
                        <VideoModal/>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default VideoSection;