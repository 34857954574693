import pimg1 from '../images/Home/img1.jpg'
import pimg2 from '../images/Home/img2.jpg'
import pimg3 from '../images/Home/img3.jpg'
import pimg4 from '../images/Home/img4.jpg'
import pimg5 from '../images/Home/img5 square.jpg'
import pimg6 from '../images/Home/img6 sqaure.jpg'
import pimg7 from '../images/Home/img7 square.jpg'
import pimg8 from '../images/Home/img8 square.jpg'

import pSimg1 from '../images/portfolio-single/1.jpg'
import pSimg2 from '../images/portfolio-single/2.jpg'
import pSimg3 from '../images/portfolio-single/3.jpg'
import pSimg4 from '../images/portfolio-single/4.jpg'
import pSimg5 from '../images/portfolio-single/5.jpg'
import pSimg6 from '../images/portfolio-single/6.jpg'
import pSimg7 from '../images/portfolio-single/7.jpg'



const Projects = [
    {
        id: '1',
        title:'Maria & Robin',
        pimg1:pimg1,
        pSimg:pSimg1,
        date:'Feb 2019',
        location:'Austria',
    },
    {
        id: '2',
        title:'Rose & Harry',
        pimg1:pimg2,
        pSimg:pSimg2,
        date:'Jan 2019',
        location:'Austria',
    },
    {
        id: '3',
        title:'Aliana & David',
        pimg1:pimg3,
        pSimg:pSimg3,
        date:'Mar 2020',
        location:'Austria',
    },
    {
        id: '4',
        title:'Aliza & Ken',
        pimg1:pimg4,
        pSimg:pSimg4,
        date:'Apr 2020',
        location:'Austria',
    },
    {
        id: '5',
        title:'Michel & Dianee',
        pimg1:pimg5,
        pSimg:pSimg5,
        date:'Jan 2021',
        location:'Austria',
    },
    {
        id: '6',
        title:'Maria & Nepela',
        pimg1:pimg6,
        pSimg:pSimg5,
        date:'Jun 2021',
        location:'Austria',
    },
    {
        id: '7',
        title:'Maria & Nevela',
        pimg1:pimg7,
        pSimg:pSimg5,
        date:'Jun 2021',
        location:'Austria',
    },
    {
        id: '8',
        title:'Maria & Nevela',
        pimg1:pimg8,
        pSimg:pSimg5,
        date:'Jun 2021',
        location:'Austria',
    }
    
]
export default Projects;