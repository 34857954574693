import React from 'react'
import {Link}  from 'react-router-dom'

const HeaderTopbar = () => {
    return(	
        <section className="topbar">
            <h2 className="hidden">some</h2>
            <div className="container-fluid">
                <div className="row">
                    <div className="col col-lg-7 col-md-12 col-12">
                        <div className="contact-intro">
                            <ul>
                                <li><i className="fi flaticon-email"></i>deepakstudios123@gmail.com</li>
                                <li><i className="fi flaticon-phone-call"></i>+91 9811536164</li>
                                <li><i className="fi flaticon-maps-and-flags"></i> D/565 Tagore Garden N.D 110027
</li>
                            </ul>
                        </div>
                    </div>
                    <div className="col col-lg-5 col-md-12 col-12">
                        <div className="contact-info">
                            <ul>
                                <li><a target='_blank' href="https://www.instagram.com/deepakstudios?igsh=MTBrb3pzNDh6cWUxaQ%3D%3D&utm_source=qr"><i className="fi flaticon-instagram-1"></i></a></li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default HeaderTopbar;