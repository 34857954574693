import React, { Component } from "react";



class PartnerSection extends Component {
    render() {
        return (
            <></>
            
        );
    }
}

export default PartnerSection;