import simg1 from '../images/service/img-1.jpg'
import simg2 from '../images/service/img-2.jpg'
import simg3 from '../images/service/img-3.jpg'
import simg4 from '../images/service/img-4.jpg'
import simg5 from '../images/service/img-5.jpg'
import simg6 from '../images/service/img-6.jpg'

import sinnermg1 from '../images/service-single/2.jpg'
import sinnermg2 from '../images/service-single/3.jpg'

import singleImg1 from '../images/service-single/1.jpg'
import singleImg2 from '../images/service-single/4.jpg'
import singleImg3 from '../images/service-single/5.jpg'
import singleImg4 from '../images/service-single/6.jpg'
import singleImg5 from '../images/service-single/7.jpg'
import singleImg6 from '../images/service-single/8.jpg'

import img1 from "../images/Home/img1.jpg";
import img2 from "../images/Home/img2.jpg";
import img3 from "../images/Home/img3.jpg";
import img4 from "../images/Home/img4.jpg";
import img5 from "../images/Home/img5.jpg";
import img6 from "../images/Home/story.jpg";

const Services = [
    {
        id: '1',
        fIcon1:'flaticon-gallery',
        title:'Couples',
        description:'Quis ipsome suspendisse ultrices gravid. Risus commod viverra maescums.',
        simg1:img5,
        ssImg:singleImg1,
        sinnerImg1:sinnermg1,
        sinnerImg2:sinnermg2,
    },
    {
        id: '2',
        fIcon1:'flaticon-serving-dish',
        title:'Candid Shots',
        description:'Quis ipsome suspendisse ultrices gravid. Risus commod viverra maescums.',
        simg1:img2,
        ssImg:singleImg2,
        sinnerImg1:sinnermg1,
        sinnerImg2:sinnermg2,
    },
    {
        id: '3',
        fIcon1:'flaticon-edit',
        title:'Pre-Wedding',
        description:'Quis ipsome suspendisse ultrices gravid. Risus commod viverra maescums.',
        simg1:img6,
        ssImg:singleImg3,
        sinnerImg1:sinnermg1,
        sinnerImg2:sinnermg2,
    },
    {
        id: '4',
        fIcon1:'flaticon-wedding',
        title:'Bride',
        description:'Quis ipsome suspendisse ultrices gravid. Risus commod viverra maescums.',
        simg1:img4,
        ssImg:singleImg4,
        sinnerImg1:sinnermg1,
        sinnerImg2:sinnermg2,
    },
    
]
export default Services;