import React, { Component } from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import tsImg1 from '../../images/testimonial/l-img.jpg'
import tsImg2 from '../../images/testimonial/shape.png'
import tsImg3 from '../../images/testimonial/shape2.png'

import img1 from "../../images/Home/img1.jpg";
import img2 from "../../images/Home/img2.jpg";
import img3 from "../../images/Home/img5.jpg";
import img4 from "../../images/Home/img4.jpg";
import { Hidden } from "@material-ui/core";




const Testimonials = [
    {
      tstImg:img2,
      name:'Jenefer Marvella',
      stars:5,
      descriptoion:'This is not only a wedding planning agency but also a dreamy friend. I am very glad to work with them.They make my dream come true. In my wedding I found them as my best friends.',
    },
    {
      tstImg:img3,
      name:'Jenefer Marvella',
      stars:3,
      descriptoion:'This is not only a wedding planning agency but also a dreamy friend. I am very glad to work with them.They make my dream come true. In my wedding I found them as my best friends.',
    },
    {
      tstImg:img4,
      name:'Aliza Anney',
      stars:4,
      descriptoion:'This is not only a wedding planning agency but also a dreamy friend. I am very glad to work with them.They make my dream come true. In my wedding I found them as my best friends.',
    },
]



class Testimonial extends Component {
    
    render() {
        var settings = {
            dots: true,
            arrows: false,
            speed: 1200,
            slidesToShow: 1,
            slidesToScroll: 1,
            autoplay: true,
            autoplaySpeed: 3500,
        };

        return (
            <section className="wpo-testimonials-section section-padding">
                <div className="container">
                    <div className="row align-items-center">
                        <div className="col-lg-4 col-12">
                            <div className="wpo-testimonials-img">
                                <img  src={img1} alt=""/>
                                <div className="wpo-testimonials-img-shape">
                                    <img src={tsImg2} alt=""/>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-7 offset-lg-1 col-12">
                            <div className="wpo-testimonials-wrap">
                                <h2>What Our Client Say</h2>
                                <div className="wpo-testimonials-active owl-carousel">
                                    <Slider {...settings}>
                                        {Testimonials.map((tstml, tsm) => (
                                            <div className="wpo-testimonials-item" key={tsm}>
                                                <p>{tstml.descriptoion}</p>
                                                <div className="wpo-testimonial-info">
                                                    <div className="wpo-testimonial-info-img">
                                                        <img style={{height:"70px", width:"70px"}} src={tstml.tstImg} alt=""/>
                                                    </div>
                                                    <div className="wpo-testimonial-info-text">
                                                        <h5>{tstml.name}</h5>
                                                        {[...Array(tstml.stars)].map((_, i) => (
                                                            <span key={i}><svg xmlns="http://www.w3.org/2000/svg" height="20" width="22.5" viewBox="0 0 576 512"><path fill="#FFD43B" d="M316.9 18C311.6 7 300.4 0 288.1 0s-23.4 7-28.8 18L195 150.3 51.4 171.5c-12 1.8-22 10.2-25.7 21.7s-.7 24.2 7.9 32.7L137.8 329 113.2 474.7c-2 12 3 24.2 12.9 31.3s23 8 33.8 2.3l128.3-68.5 128.3 68.5c10.8 5.7 23.9 4.9 33.8-2.3s14.9-19.3 12.9-31.3L438.5 329 542.7 225.9c8.6-8.5 11.7-21.2 7.9-32.7s-13.7-19.9-25.7-21.7L381.2 150.3 316.9 18z"/></svg></span>
                                                        ))}
                                                    </div>
                                                </div>
                                            </div>
                                        ))}
                                    </Slider>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="wpo-testimonials-shape">
                    <img src={tsImg3} alt=""/>
                </div>
            </section>
        )
    }
}

export default Testimonial;