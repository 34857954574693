import React from 'react'
import {Link}  from 'react-router-dom'
import Projects from '../../api/projects'
import Logo from '../../images/logo.png'


const Footer = (props) =>{

    const ClickHandler = () =>{
        window.scrollTo(10, 0);
     }

  return(
    <footer className="wpo-site-footer">
        <div className="wpo-upper-footer">
            <div className="container-fluid">
                <div className="row">
                    <div className="col col-xl-3 col-lg-4 col-md-6 col-sm-12 col-12">
                        <div className="widget about-widget">
                            <div className="logo widget-title">
                                <img src={Logo} alt="logo"/>
                            </div>
                            <p>Welcome and open yourself to your truest love this year with us! With the Release
                                Process</p>
                            <ul>
                                
                                <li>
                                    <a target='_blank' href="https://www.instagram.com/deepakstudios?igsh=MTBrb3pzNDh6cWUxaQ%3D%3D&utm_source=qr">
                                        <i className="ti-instagram"></i>
                                    </a>
                                </li>
                            </ul>
                        </div>
                    </div>
                    <div className="col col-xl-3  col-lg-4 col-md-6 col-sm-12 col-12">
                        <div className="widget link-widget">
                            <div className="widget-title">
                                <h3>Information</h3>
                            </div>
                            <ul>
                                <li><Link onClick={ClickHandler} to="/">Home</Link></li>
                                <li><Link onClick={ClickHandler} to="/portfolio-grid">Gallery</Link></li>
                                <li><Link onClick={ClickHandler} to="/shop">Pricing</Link></li>
                                <li><Link onClick={ClickHandler} to="/contact">Contact us</Link></li>
                            </ul>
                        </div>
                    </div>
                    <div className="col col-xl-3  col-lg-4 col-md-6 col-sm-12 col-12">
                        <div className="widget wpo-service-link-widget">
                            <div className="widget-title">
                                <h3>Contact </h3>
                            </div>
                            <div className="contact-ft">
                                <p>Would you have any enquiries.Please feel free to contact us</p>
                                <ul>
                                    <li><i className="fi flaticon-email"></i>deepakstudios123@gmail.com</li>
                                    <li><i className="fi flaticon-phone-call"></i>+91 9811536164</li>
                                    <li><i className="fi flaticon-maps-and-flags"></i>D/565 Tagore Garden N.D 110027
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>

                    <div className="col col-xl-3  col-lg-4 col-md-6 col-sm-12 col-12">
                        <div className="widget instagram">
                            <div className="widget-title">
                                <h3>Instagram</h3>
                            </div>
                            <ul className="d-flex">
                                {Projects.slice(0,6).map((project, pitem) => (
                                    <li key={pitem}><Link onClick={ClickHandler} to={`/project-single/${project.id}`}><img src={project.pimg1} alt=""/></Link></li>
                                ))}
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div className="wpo-lower-footer">
            <div className="container">
                <div className="row">
                    <div className="col col-xs-12">
                        <p className="copyright"> &copy; {" "}Copyright 2024 Deepak Studios | Privacy Policy | All rights reserved</p>
                    </div>
                </div>
            </div>
        </div>
    </footer>
  )
} 

export default Footer;